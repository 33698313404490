import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const InputContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  height: 8.125vw;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  textarea {
    padding: 0 1vw;
    -webkit-appearance: none;
    height: 90%;
    width: calc(100% - (1vw * 2));
  }
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #333333;
  letter-spacing: 0.1px;
  margin-bottom: 0.9375vw;
`;
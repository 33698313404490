import React, { useState } from 'react';
import CTA from '../CTA';
import Row from '../../Row';
import Column from '../../Column';
import Image from '../../ImageBasic';
import Button from '../../Button1';
import Input from '../../ThemedInput';
import TextArea from '../../TextArea';
import {
  Container , Title, SubTitle,
  IconContainer, ContactType, Detail,
} from './styled.js';
import EmailSVG from './email.svg';
import PhoneSVG from './phone.svg';
import AddressSVG from './address.svg';

function ContactUs() {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [website, setWebsite] = useState(null);
  const [question, setQuestion] = useState(null);
  return (
    <Container>
      <Column style={{ height: 322, backgroundColor: '#F3F0EB' }}>
        <Title style={{ margin: 30, marginBottom: 0 }}>Contact Us</Title>
        <SubTitle style={{ margin: 30 }}>We will get back to you soon!</SubTitle>
      </Column>
      <Column style={{ margin: 30 }}>
        <Row style={{ flexWrap: 'wrap' }}>
          <Row style={{ marginBottom: 15, marginRight: 15 }}>
            <IconContainer>
              <Image style={{ width: 50 }} src={EmailSVG} />
            </IconContainer>
            <Column>
              <ContactType>Email</ContactType>
              <Detail>info@sobridely.com</Detail>
            </Column>
          </Row>
          <Row style={{ marginBottom: 15, marginRight: 15 }}>
            <IconContainer>
              <Image style={{ width: 50 }} src={PhoneSVG}/>
            </IconContainer>
            <Column>
              <ContactType>Phone</ContactType>
              <Detail>(925) 812-3927</Detail>
            </Column>
          </Row>
          <Row style={{ marginBottom: 15, marginRight: 15 }}>
            <IconContainer>
              <Image style={{ width: 50 }} src={AddressSVG} />
            </IconContainer>
            <Column>
              <ContactType>Address</ContactType>
              <Detail>Sacramento, CA</Detail>
            </Column>
          </Row>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Input style={{ marginRight: 15 }} value={name} update={setName} label="Name" />
          <Input value={email} update={setEmail} label="Email" />
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Input type="phoneNumber" style={{ marginRight: 15 }} value={phoneNumber} update={setPhoneNumber} label="Phone Number" />
          <Input value={website} update={setWebsite} label="Website" />
        </Row>
        <Row style={{ width: '100%', marginBottom: 15 }} >
          <TextArea style={{ width: '100%', height: 100 }} value={question} update={setQuestion} label="What's your question" />
        </Row>
        <Button>Send Message</Button>
      </Column>
      <CTA />
    </Container>
  );
}

export default ContactUs;
import React from 'react';
import styled from 'styled-components';

const Container = styled.textarea`
  justify-content: center;
  align-items: center;
  display: flex;
  background: #FFFFFF;
  border: 1px solid #E4E5EC;
  box-shadow: 0 2px 1px 0 rgba(0,0,0,0.03);
  height: 80px;
  width: calc(100% - 10px);
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #898EA2;
  letter-spacing: 0;
  line-height: 1.2em;
  padding-left: 10px;
  padding-top: 10px;
`;

function Input({
  label, value, update = () => {}, ...other
}) {
  return (
    <Container {...other} onChange={(e) => update(e.target.value)} placeholder={label}>{value}</Container>
  );
}

export default Input;

import React from 'react';
import { Container } from './styled';
import Background from './Background.png';
import NextArrow from './NextArrowIcon.svg';

function CTA() {
  return (
    <Container>
      <img src={Background} />
      <div>Find your dream wedding gown.</div>
      <div>
        <div>Sign Up Now</div>
        <img src={NextArrow} />
      </div>
    </Container>
  );
}

export default CTA;

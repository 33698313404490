import React from 'react';
import {
  Container, InputContainer, Title,
} from './styled';

function Form({
  value, onChange, title, icon,
}) {
  return (
    <Container>
      <Title>{title}</Title>
      <InputContainer>
        <img src={icon} />
        <input onChange={({ target: { value } }) => onChange(value)} value={value} />
      </InputContainer>
    </Container>
  );
}

export default Form;

import styled from 'styled-components';

export const Container = styled.div` 
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  background: white;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 14.25vw;
`;

export const Heading = styled.div`
  width: 77.50vw;
  display: flex;
  justify-content: space-between;
  margin-top: 7.500vw;
`;

export const Button = styled.div`
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 1.438vw;
  z-index: 1;
  position: relative;
  background-color: #FE95A2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17.56vw;
  height: 4.000vw;
  > div {
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
  }
  > img {
    width: 1.375vw;
    margin-left: 0.5625vw;
  }
`;

export const HeadingItem = styled.div`
  display: flex;
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.6250vw;
  }
`;

export const HeadingTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.313vw;
  color: #333333;
  letter-spacing: 0;
  line-height: 32px;
`;

export const HeadingInfo = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.000vw;
  color: #666666;
  letter-spacing: 0.1px;
  line-height: 26px;
`;

export const HeadingImage = styled.div`
  height: 5.000vw;
  width: 5.000vw;
  border: 1px solid rgba(0,0,0,0.10);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 1.875vw;
  display: flex;
`;
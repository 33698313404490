import styled from 'styled-components';

export const Container = styled.div`
  background: #F3F0EB;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Heading = styled.div`
  font-family: SilverSouthSerif;
  font-size: 3.500vw;
  color: #2C2C2C;
  letter-spacing: 1.56px;
  text-transform: uppercase;
`;

export const SubHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #2C2C2C;
  letter-spacing: 1.08px;
  text-transform: uppercase;
  margin-top: 2.125vw;
`;

export const HeadingContainer = styled.div`
  height: 20.13vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 13.44vw;
  align-self: flex-start;
`;
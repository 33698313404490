import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 1.875vw;
`;

export const InputContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  height: 3.000vw;
  width: 37.75vw;
  position: relative;
  display: flex;
  align-items: center;
  img {
    top: 50%;
    left: 5%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 1.000vw;
    height: 1.000vw;
    object-fit: contain;
  }
  input {
    padding: 0 3.125vw;
    -webkit-appearance: none;
    height: 90%;
    width: calc(100% - (3.125vw * 2));
  }
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #333333;
  letter-spacing: 0.1px;
  margin-bottom: 0.9375vw;
`;
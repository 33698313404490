import React, { Component } from 'react';
import { Container } from './styled';
import NameIcon from './NameIcon.svg';
import EmailIcon from './EmailIcon.svg';
import PhoneIcon from './PhoneIcon.svg';
import WebsiteIcon from './WebsiteIcon.svg';
import Form from './Form';
import TextInput from './TextInput';

export class ContactForms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container>
        <Form
          value={this.state.name}
          onChange={(name) => this.setState({ name })}
          icon={NameIcon}
          title="Name *" />
        <Form
          value={this.state.email}
          onChange={(email) => this.setState({ email })}
          icon={EmailIcon}
          title="Email *" />
        <Form
          value={this.state.phone}
          onChange={(phone) => this.setState({ phone })}
          icon={PhoneIcon}
          title="Phone *" />
        <Form
          value={this.state.website}
          onChange={(website) => this.setState({ website })}
          icon={WebsiteIcon}
          title="Website *" />
        <TextInput
          value={this.state.question}
          onChange={(question) => this.setState({ question })} title="What's your question" />
      </Container>
    );
  }
}

export default ContactForms;

import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Detail = styled.div`
  font-family: sofia-pro;
  font-weight: 400;
  font-size: 12px;
  color: #6B969D;
  letter-spacing: 0.1px;
  line-height: 26px;
`;

export const ContactType = styled.div`
  font-family: sofia-pro;
  font-weight: 400;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 32px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

export const SubTitle = styled.div`
  font-family: SofiaProBlack;
  font-size: 14px;
  color: #124C56;
  letter-spacing: 1.08px;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 45px;
  color: #124C56;
  letter-spacing: 1.56px;
`;
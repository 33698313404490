import React from 'react';
import {
  Container, Heading, Button, HeadingItem, HeadingTitle, HeadingInfo, HeadingImage,
} from './styled';
import ContactIcon from './ContactIcon.svg';
import PhoneIcon from './PhoneIcon.svg';
import AddressIcon from './AddressIcon.svg';
import ContactForms from './ContactForms';

const contact_items = [
  {
    title: 'Email',
    content: 'info@sobridely.com',
    img: ContactIcon,
  },
  {
    title: 'Phone',
    content: '925 - 812 - 3927',
    img: PhoneIcon,
  },
  {
    title: 'Address',
    content: 'Sacramento, CA',
    img: AddressIcon,
  },
];

function Body() {
  return (
    <Container>
      <Heading>
        {
          contact_items.map(({
            title, content, img,
          }, index) => (
            <HeadingItem key={index}>
              <HeadingImage>
                <img src={img}/>
              </HeadingImage>
              <div>
                <HeadingTitle>{title}</HeadingTitle>
                <HeadingInfo>{content}</HeadingInfo>
              </div>
            </HeadingItem>
          ))
        }
      </Heading>
      <ContactForms />
      <Button>
        <div>Send Message</div>
      </Button>
    </Container>
  );
}

export default Body;

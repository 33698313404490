import React from 'react';
import {
  Container, Heading, SubHeading, HeadingContainer,
} from './styled';
import Body from './Body';
import CTA from './CTA';

function Media() {
  return (
    <Container>
      <HeadingContainer>
        <Heading>Contact Us</Heading>
        <SubHeading>We will get back to you soon</SubHeading>
      </HeadingContainer>
      <Body />
      <CTA />
    </Container>
  );
}

export default Media;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//components
import Layout from '../components/Layout';
import ContactUsDesktop from '../components/Desktop/ContactUs';
import ContactUsMobile from '../components/Mobile/ContactUs';
//actions
import { setMetaTags } from '../actions/homeActions';
//styles
import './style.css';

function ContactUs({ setMetaTags }) {
  useEffect(() => {
    const description = 'Reset your password';
    const image = '';
    const url = window.location.href;
    const title = `SoBridely`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);
  return (
    <Layout
      mobile={<ContactUsMobile />}
      desktop={<ContactUsDesktop />} />
  );
}

const mapDispatchToProps = { setMetaTags };

export default connect(null, mapDispatchToProps)(ContactUs);